import * as React from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Progress from 'reactstrap/lib/Progress';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons/faCircle';
import { ROUTE } from '../config';
import SignupForm from '../components/auth/SignupForm';
import AddPaymentSourceButton from '../components/dropin/AddPaymentSourceButton';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import MerchantImg from '../components/MerchantImg';
import { bindActionCreators, Dispatch } from 'redux';
import { isNewPaymentSourceLinkedAction } from '../store/actions';

interface StateProps {
    merchantDisplayName: string;
}

interface DispatchProps {
    isNewPaymentSourceLinkedAction: typeof isNewPaymentSourceLinkedAction;
}

type OwnProps = { navToLogin?: () => void } & RouteComponentProps;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ code: string }>;

interface State {
    step: number;
    code?: string;
}

interface Step {
    step: number;
    title: string;
}

class SignUpPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            step: 1,
            code: this.props.match.params.code,
        };
    }

    next = () => {
        if (this.state.code) {
            window.location.reload();
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    };

    previous = () => {
        this.setState({ step: this.state.step - 1 });
    };

    getStep(step: Step, key: number) {
        let icon = faCircle;
        let title = <React.Fragment>{step.title}</React.Fragment>;
        const classes = ['p-2', 'd-flex', 'align-items-center'];
        if (step.step === this.state.step) {
            icon = faDotCircle;
            title = <strong>{title}</strong>;
        } else if (step.step > this.state.step) {
            icon = faCircleRegular;
        }

        if (step.step !== this.state.step) {
            classes.push('border-bottom');
        }

        if (step.step > 1) {
            classes.push('border-left');
        }

        return (
            <Col key={key} className={classes.join(' ')}>
                <FontAwesomeIcon icon={icon} className="mr-1 text-primary" />
                <small>{title}</small>
            </Col>
        );
    }

    onLinkSuccess = () => {
        this.props.isNewPaymentSourceLinkedAction(true);
        this.props.history.push(ROUTE.HOME);
    };

    render() {
        const steps: Step[] = [
            {
                step: 1,
                title: 'Personal Details',
            },
            {
                step: 2,
                title: 'Link Account',
            },
        ];

        const step = steps.find((el) => el.step === this.state.step) || steps[0];

        let content = <SignupForm onSuccess={this.next} payLinkId={this.state.code} />;

        if (step.step === 2) {
            content = (
                <>
                    <p>In order to make payments, you must securely link a bank account.</p>
                    <p>Your credentials will never be stored or shared.</p>

                    <div>
                        <AddPaymentSourceButton onSuccess={this.onLinkSuccess} block>
                            Link and Finish Sign Up
                        </AddPaymentSourceButton>
                    </div>
                </>
            );
        }

        return (
            <Layout navbar={false} centered>
                <Card className="shadow-lg mw-sm">
                    <div className="text-center p-3 px-md-5">
                        <div className="h4">
                            Sign up for Payments at <strong>{this.props.merchantDisplayName}</strong>
                        </div>
                        <MerchantImg />
                        <div className="lead">We need a few details in order to create your account.</div>
                    </div>

                    {step.step < 2 ? (
                        <div className="p-3 mb-4">
                            <div className="text-center p-3 border border-primary" style={{ borderRadius: '4px' }}>
                                Paid using Stronghold ACH before?
                                {this.props.navToLogin ? (
                                    <button
                                        onClick={this.props.navToLogin}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'blue',
                                            cursor: 'pointer',
                                            fontSize: 'inherit',
                                            padding: 5,
                                        }}
                                    >
                                        Log In
                                    </button>
                                ) : (
                                    <Link to={ROUTE.LOGIN} style={{ padding: 5, textDecoration: 'underline' }}>
                                        Log In{' '}
                                    </Link>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <Progress
                        className="rounded-0"
                        value={(step.step * 100) / steps.length}
                        color="primary"
                        style={{ height: 5 }}
                    />
                    <Row noGutters>{steps.map((el, index) => this.getStep(el, index))}</Row>
                    <CardBody>{content}</CardBody>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (
    state: ApplicationState,
): StateProps => ({
    merchantDisplayName: state.global.merchant.display_name,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            isNewPaymentSourceLinkedAction: isNewPaymentSourceLinkedAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpPage));
