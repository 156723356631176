import 'react';
import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { Card, CardBody } from 'reactstrap';
import Layout from '../components/Layout';
import { Redirect } from 'react-router';
import { ROUTE } from '../config';
import selectors from '../store/selectors';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import CardHeader from 'reactstrap/lib/CardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard';
import AddPaymentSourceButton from '../components/dropin/AddPaymentSourceButton';
import { PaymentSource } from '@stronghold/pay-dropin';
import StrongholdPay from '../components/StrongholdPay';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import ChargesTable from '../components/charge/ChargesTable';
import { fetchChargesAction, deactivatePaymentSourceAction } from '../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import SupportCard from '../components/SupportCard';
import PaymentSourceActions from '../components/PaymentSourceActions';
import { uniqBy } from 'lodash';
import ToggleSplit from '../components/split/ToggleSplit';
import { SPLIT_FLAG } from '../store/types';
import Merchant from '../components/Merchant';
import NewPaymentSourceLinkedModal from '../components/NewPaymentSourceLinkedModal';

interface StateProps {
    isAuthenticated: boolean;
    fullName: string;
    email: string | null;
    mobile: string | null;
    paymentSources: PaymentSource[];
    customerId: string | null;
    isNewPaymentSourceLinked: boolean;
}

interface DispatchProps {
    fetchChargesAction: typeof fetchChargesAction;
    deactivatePaymentSourceAction: typeof deactivatePaymentSourceAction;
}

type Props = DispatchProps & StateProps;

class HomePage extends React.Component<Props> {
    componentDidMount = async () => {
        this.fetchCharges();
    };

    fetchCharges = (skip?: number) => {
        this.props.fetchChargesAction({ skip });
    };

    render() {
        const { isAuthenticated, customerId, paymentSources, isNewPaymentSourceLinked } = this.props;

        if (!isAuthenticated) {
            return <Redirect to={ROUTE.LOGIN} />;
        }

        return (
            <Layout className="container">
                <Row>
                    <Col lg={8} className="order-2 order-lg-1">
                        <Card>
                            <CardHeader className="d-flex align-items-center">
                                <FontAwesomeIcon size={'lg'} icon={faIdCard} className="mr-2" />
                                <div>Profile</div>
                            </CardHeader>
                            <CardBody className="">
                                <Row className="mb-3">
                                    <Col className="font-weight-bold" xs={4}>
                                        Name
                                    </Col>
                                    <Col>{this.props.fullName}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="font-weight-bold" xs={4}>
                                        Mobile
                                    </Col>
                                    <Col className="">{this.props.mobile || '—'}</Col>
                                </Row>
                                <Row>
                                    <Col className="font-weight-bold" xs={4}>
                                        Email
                                    </Col>
                                    <Col className="">{this.props.email || '—'}</Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon size={'lg'} icon={faUniversity} className="mr-2" />
                                    <div>Payment Methods</div>
                                </div>
                            </CardHeader>
                            <ul className="list-group list-group-flush">
                                {customerId &&
                                    paymentSources &&
                                    uniqBy(paymentSources, 'unique_hash').map((el) => (
                                        <li
                                            key={el.id}
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            <div className="h5 my-0">{el.label}</div>
                                            <div className="h5 my-0">
                                                <PaymentSourceActions paymentSource={el} customerId={customerId} />
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                            <CardBody>
                                <div className="d-flex justify-content-end">
                                    <AddPaymentSourceButton size={'sm'} />
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="d-flex align-items-center">
                                <FontAwesomeIcon size={'lg'} icon={faReceipt} className="mr-2" />
                                <div>Transactions</div>
                            </CardHeader>
                            <ChargesTable onGoTo={(page, skip) => this.fetchCharges(skip)} />
                        </Card>
                    </Col>
                    <Col lg={4} className="order-1 order-lg-2">
                        <Card>
                            <CardBody className="text-center">
                                <ToggleSplit flag={SPLIT_FLAG.SIMPLE_CHECKOUT} unactiveComponent={<Merchant />}>
                                    <StrongholdPay />
                                </ToggleSplit>
                            </CardBody>
                        </Card>
                        <SupportCard />
                    </Col>
                </Row>
                <NewPaymentSourceLinkedModal showModal={isNewPaymentSourceLinked} />
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    fullName: selectors.authentication.getFullName(state.authentication),
    customerId: state.authentication.customer.id,
    email: state.authentication.customer.individual.email,
    mobile: state.authentication.customer.individual.mobile,
    paymentSources: state.paymentSources.arr,
    isNewPaymentSourceLinked: state.paymentSources.isNewPaymentSourceLinked,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchChargesAction,
            deactivatePaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
