import * as React from 'react';
import { FormEvent } from 'react';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
    resendTokenAction,
    resetActionStatusAction,
    setPayLinkCustomerAction,
    signupAction,
} from '../../store/actions';
import { ApplicationState } from '../../store';
import {
    AuthenticationMethod,
    CustomerModel,
    addCustomer,
    ResponseError,
    ResponseErrorCode,
    PayLinkModel,
} from '../../apis';
import selectors from '../../store/selectors';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { FORMAT_DATE } from '../../config';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import PhoneNumberInput from '../PhoneNumberInput';
import querystring from 'querystring';
import { Link } from 'react-router-dom';
import VerificationCodeModal from '../VerificationCodeModal';

interface StateProps {
    isRequesting: boolean;
    error: ResponseError | null;
    customer: CustomerModel | null;
    link: PayLinkModel | null;
    environment: string;
}

interface DispatchProps {
    resetForm: typeof resetActionStatusAction;
    signup: typeof signupAction;
    resend: typeof resendTokenAction;
    linkCustomer: typeof setPayLinkCustomerAction;
}

interface OwnProps {
    onSuccess: () => void;
    payLinkId?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    first_name: string;
    last_name: string;
    date_of_birth: Date | null;
    mobile: string;
    email: string;
    authenticationMethod: AuthenticationMethod;
    show_sms: boolean;
    showModal: boolean;
}

function onSubmit(fn: () => void) {
    return (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fn();
    };
}

class SignupForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            date_of_birth: null,
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            authenticationMethod: 'sms',
            show_sms: true,
            showModal: false,
        };

        this.onSignup = this.onSignup.bind(this);
    }

    toggleAuthMethod = (authMethod: AuthenticationMethod) =>
        this.setState({
            show_sms: authMethod === 'sms',
            authenticationMethod: authMethod,
        });

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    componentDidMount(): void {
        this.props.resetForm('signup');
    }

    dobRaw = () => (this.state.date_of_birth ? moment(this.state.date_of_birth).format(FORMAT_DATE) : '');

    async onSignup() {
        try {
            await this.props.signup(
                this.state.first_name,
                this.state.last_name,
                this.dobRaw(),
                this.state.mobile,
                this.state.email,
                this.state.authenticationMethod,
                this.props.link?.merchant.short_name,
            );
            if (!this.props.error) {
                // Display verification modal
                this.setState({ showModal: true });
            }
            if (this.props.payLinkId && this.props.customer?.id) {
                await addCustomer(this.props.payLinkId, this.props.customer.id);
            }
        } catch (error) {
            // catching error now
        }
    }

    tryLogin = (isMobile: boolean) => {
        let query;
        if (isMobile) {
            query = querystring.stringify({ mobile: this.state.mobile });
        } else {
            query = querystring.stringify({ email: this.state.email });
        }
        return (
            <React.Fragment>
                {isMobile ? 'Mobile phone number' : 'Email'} already taken. Please{' '}
                <Link to={`/login?${query}`}>login</Link> using this {isMobile ? 'phone number' : 'email address'}.
            </React.Fragment>
        );
    };

    onResend = async () => {
        try {
            await this.props.resend(this.state.mobile, this.state.email, this.state.authenticationMethod);
            this.setState({ showModal: true });
        } catch (error) {
            // Error
        }
    };

    render() {
        const { error, isRequesting } = this.props;

        return (
            <React.Fragment>
                <Form onSubmit={onSubmit(this.onSignup)} noValidate>
                    <FormGroup>
                        <Label>First Name</Label>
                        <Input
                            id={'input-fname'}
                            key={'input-fname'}
                            type="text"
                            placeholder="Your First Name"
                            autoComplete="given-name"
                            value={this.state.first_name}
                            onChange={(e) => this.setState({ first_name: e.currentTarget.value })}
                            invalid={selectors.global.isPropertyError('individual.first_name', error)}
                        />
                        <FormFeedback>{selectors.global.getErrorMessage(error)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                            id={'input-lname'}
                            type="text"
                            placeholder="Your Last Name"
                            autoComplete="family-name"
                            value={this.state.last_name}
                            onChange={(e) => this.setState({ last_name: e.currentTarget.value })}
                            invalid={selectors.global.isPropertyError('individual.last_name', error)}
                        />
                        <FormFeedback>{selectors.global.getErrorMessage(error)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Date of Birth</Label>
                        <ReactDatePicker
                            className={[
                                'form-control',
                                selectors.global.isPropertyError('individual.date_of_birth', error) ? 'is-invalid' : '',
                            ].join(' ')}
                            placeholderText={'11/30/1990'}
                            autoComplete="dob"
                            selected={this.state.date_of_birth}
                            wrapperClassName={'w-100'}
                            dateFormat={'MM/dd/yyyy'}
                            showYearDropdown
                            scrollableYearDropdown={true}
                            yearDropdownItemNumber={100}
                            showMonthDropdown={true}
                            scrollableMonthYearDropdown={true}
                            maxDate={moment().add(-15, 'y').toDate()}
                            calendarClassName={'date-picker-calendar'}
                            popperClassName={'date-picker-popper'}
                            dayClassName={() => 'DAY'}
                            onChange={(date) => {
                                this.setState({ date_of_birth: date });
                            }}
                        />
                        <p className="text-danger small">
                            {selectors.global.isPropertyError('individual.date_of_birth', error)
                                ? selectors.global.getErrorMessage(error)
                                : ''}
                        </p>
                    </FormGroup>
                    <FormGroup inline>
                        <Label>Preferred Contact Method</Label>
                        <div className="radio">
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="SMS"
                                    checked={this.state.authenticationMethod === 'sms'}
                                    onChange={() => {
                                        this.toggleAuthMethod('sms');
                                    }}
                                />
                                SMS
                            </label>
                            <label className={'contact-method'}>
                                <input
                                    type="radio"
                                    value="Email"
                                    checked={this.state.authenticationMethod === 'email'}
                                    onChange={() => {
                                        this.toggleAuthMethod('email');
                                    }}
                                />
                                Email
                            </label>
                        </div>
                        {this.state.show_sms ? (
                            <FormGroup>
                                <PhoneNumberInput
                                    placeholder="Enter mobile phone number"
                                    value={this.state.mobile}
                                    isInvalid={selectors.global.isPropertyError('individual.mobile', error)}
                                    onChange={(mobile: string) => this.setState({ mobile })}
                                />
                                <FormFeedback>
                                    {selectors.global.getErrorCode(error) === ResponseErrorCode.ValueTaken
                                        ? this.tryLogin(true)
                                        : selectors.global.getErrorMessage(error)}
                                </FormFeedback>
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <Input
                                    id={'input-email'}
                                    key={'input-email'}
                                    type="email"
                                    placeholder="Enter email address"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.currentTarget.value })}
                                    invalid={selectors.global.isPropertyError('individual.email', error)}
                                />
                                <FormFeedback>
                                    {selectors.global.getErrorCode(error) === ResponseErrorCode.ValueTaken
                                        ? this.tryLogin(false)
                                        : selectors.global.getErrorMessage(error)}
                                </FormFeedback>
                            </FormGroup>
                        )}
                    </FormGroup>
                    <Button type={'submit'} disabled={isRequesting} color={'primary'} block>
                        Sign Up{' '}
                        {isRequesting ? (
                            <Spinner type="grow" color="white" size={'sm'} />
                        ) : (
                            <FontAwesomeIcon icon={faLongArrowAltRight} fixedWidth />
                        )}
                    </Button>

                    <FormGroup className="mt-1" style={{ lineHeight: 1.1 }}>
                        <small>
                            By clicking <strong>Sign Up</strong>, you agree to receive SMS messages for account
                            verification and security purposes. Message frequency varies. Message and data rates may
                            apply. Reply HELP for info, STOP to cancel.{' '}
                        </small>
                        <br />
                        <small>
                            View our Privacy Policy and SMS Terms at{' '}
                            <a href="https://stronghold.co/legal">https://stronghold.co/legal</a>
                        </small>
                    </FormGroup>
                </Form>
                <VerificationCodeModal
                    show={this.state.showModal}
                    value={this.state.authenticationMethod === 'sms' ? this.state.mobile : this.state.email}
                    isResending={isRequesting}
                    onClose={this.toggleModal}
                    onResend={this.onResend}
                    authenticationMethod={this.state.authenticationMethod}
                    onSuccess={() => this.props.onSuccess()}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    link: state.payLink.data ? state.payLink.data : null,
    error: selectors.global.getResponseError(state.global.actions, 'signup'),
    isRequesting: selectors.global.isActionRequesting(state.global.actions, 'signup'),
    customer: state.authentication.customer,
    environment: state.global.configuration.api_environment,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            resetForm: resetActionStatusAction,
            signup: signupAction,
            resend: resendTokenAction,
            linkCustomer: setPayLinkCustomerAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
